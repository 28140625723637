import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import ArticleListItem from "../components/ListItems/ArticleListItem";

import {cssCleanClass} from "../Utils/functions";
import Breadcrumb from "../components/Breadcrumb";
import Title from "../components/Title";

const TagsArticleList = ({pageContext, data, location}) => {
    const {
        currentPage,
        numPages,
    } = pageContext

    return (
        <Layout>
            <Metas title={pageContext.name}/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <Title title="Actualités" className="text-primary"/>

                    {pageContext.tags && (
                        <div className="articles-themes flex flex-wrap my-8 -mx-2 items-center justify-start">
                            <div className="uppercase flex-initial px-2 mb-2">Filtrer par catégorie</div>
                            <div className="tags flex-initial px-2">
                                <div className="tags-wrapper flex flex-wrap items-center justify-center -mx-2">
                                    <span className="px-2 mb-2">
                                        <Link to={`/actualites`} className={`tag theme theme-tous`}>Tous</Link>
                                    </span>
                                    {pageContext.tags.map((tag, index) => {
                                        return parseInt(pageContext.tid) === parseInt(tag.id) ? (
                                            <span key={index} className="px-2 mb-2">
                                                <span className={`tag theme theme-${cssCleanClass(tag.color)} is-active`}>{tag.name}</span>
                                            </span>
                                        ) : (
                                            <span key={index} className="px-2 mb-2">
                                                <Link to={`/actualites${tag.path}`} className={`tag theme theme-${cssCleanClass(tag.color)}`}>{tag.name}</Link>
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {data.firstArticle.nodes.map((node, index) => (
                            <div key={index} className={`column${index === 0 ? ' md:col-span-2' : ''}`}>
                                <ArticleListItem content={node}/>
                            </div>
                        ))}

                        {data.articles.nodes.map((node, index) => (
                            <div key={(index + 1)} className={`column`}>
                                <ArticleListItem content={node}/>
                            </div>
                        ))}
                    </div>

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default TagsArticleList

export const query = graphql`
    query actualiteTagsListQuery($tid: Int!, $skip: Int!, $realSkip: Int!, $limit: Int!) {
        firstArticle: allNodeArticle(
          limit: 1,
          skip: $skip,
          filter: {
              status: {eq: true}
              relationships: {
                  field_tags: {
                      drupal_internal__tid: {eq: $tid}
                  }
              }
          },
          sort: {fields: created, order: DESC}
        ) {
            nodes {
                title
                body {
                    summary
                    value
                }
                path {
                    alias
                }
                relationships {
                    banner: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 992, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 480, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        color: field_color
                        path {
                            alias
                        }
                    }
                }
            }
        }
        articles: allNodeArticle(
            filter: {
                status: {eq: true},
                relationships: {
                    field_tags: {
                        drupal_internal__tid: {eq: $tid}
                    }
                }
            },
            sort: { fields: created, order: DESC },
            limit: $limit,
            skip: $realSkip
        ) {
            nodes {
                id
                title
                created
                path {
                    alias
                }
                body {
                    value
                    summary
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 480, maxHeight: 304, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        color: field_color
                        path {
                            alias
                        }
                    }
                }
            }
        }
    }`
